import request from '@/utils/request'


// 查询店铺申请列表
export function listShopApply(query) {
  return request({
    url: '/shop/shop/apply/list',
    method: 'get',
    params: query
  })
}

// 查询店铺申请分页
export function pageShopApply(query) {
  return request({
    url: '/shop/shop/apply/page',
    method: 'get',
    params: query
  })
}

// 查询店铺申请详细
export function getShopApply(data) {
  return request({
    url: '/shop/shop/apply/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺申请
export function addShopApply(data) {
  return request({
    url: '/shop/shop/apply/add',
    method: 'post',
    data: data
  })
}

// 修改店铺申请
export function updateShopApply(data) {
  return request({
    url: '/shop/shop/apply/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺申请
export function delShopApply(data) {
  return request({
    url: '/shop/shop/apply/delete',
    method: 'post',
    data: data
  })
}
export function checkApplication (data) {
  return request({
    url: '/shop/shop/apply/pass',
    method: 'post',
    data: data
  })
}
